import { DeliverTxResponse } from '@cosmjs/stargate'
import WalletConnectProvider from "@walletconnect/web3-provider"
import { Transaction } from "@zilliqa-js/account"
import { fromBech32Address, Zilliqa } from "@zilliqa-js/zilliqa"
import BigNumber from "bignumber.js"
import { AddressUtils, CarbonSDK, ChainInfo, CosmosLedger, KeplrAccount, KeplrWindow, LeapAccount, MetaMask, NeoLedgerAccount, O3Types, O3Wallet, Zilpay } from "carbon-js-sdk"
import { Key } from "carbon-js-sdk/node_modules/@keplr-wallet/types/build/wallet/keplr"
import { ETHClient } from "carbon-js-sdk/lib/clients"
import { WalletProvider } from "carbon-js-sdk/lib/clients/ZILClient"
import { TokensWithExternalBalance } from "carbon-js-sdk/lib/util/external"
import { appendHexPrefix } from "carbon-js-sdk/lib/util/generic"
import { BridgeLocalStorageKeys, neoChainMap, PopupPurposes, warningContent } from "constants/bridge"
import { ChainID, ChainParams, RpcUrl } from "constants/evm"
import { BN_ZERO } from "constants/math"
import { ChainNames, MetamaskProvider, Network, TrustWalletProvider, WalletNames, ZilRPCResponse } from "constants/types"
import { ethers } from "ethers"
import { all, call, delay, fork, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects"
import { updateCarbonSDK } from "store/App"
import { updateApproveAllowanceAsync, updateBridgeAsync, updateChangeNetworkAsync, updateInitWalletAsync } from "store/Async"
import { resetStakeState } from 'store/Stake'
import { addToastItem } from "store/Toast"
import { updateBridgeUI, updateMigrateUI, updatePopup } from "store/UI"
import {
  approveAllowanceAction, bridgeCarbonTokenAction, bridgeEvmTokenAction, bridgeNeoTokenAction, BridgeSliceSchema,
  bridgeZilTokenAction, connectBoltXAction, connectCarbonMetamaskAction, connectCosmosLedgerAction, connectEthLedgerAction, connectKeplrAction, connectLeapAction, connectMetamaskAction, connectNeoLedgerAction, connectNeolineAction,
  connectO3Action, connectTrustWalletAction, connectZilPayAction, convertCarbonTokensAction, convertEVMTokensAction, disconnectWalletAction,
  initWalletAction, updateAddress,
  updateBalance, updateCarbonWallet, updateChain, updateEth, updateNeo, updateTokenInfo, updateZil, WalletActionTypes
} from "store/Wallet"
import { EthLedgerAccount, EthLedgerSigner, getEvmGasLimit } from 'utils'
import { getETHClient, getNetworkMap, isEvm } from "utils/evm"
import { LeapWindow } from "utils/leap"
import { connectLedger } from 'utils/ledger'
import { parseNumber } from "utils/number"
import { parseError, uuidv4 } from "utils/strings"
import { selectState, transactionToUrl, waitforSDK } from "./Common"

const STATS_REFRESH_RATE = 300000
const ZIL_BRIDGE_GAS_LIMIT = new BigNumber(80000)
const ZIL_BRIDGE_WATCH_DELAY = 10000
const ZIL_BRIDGE_TIMEOUT = 60000
const ZIL_APPROVE_GAS_LIMIT = new BigNumber(25000)

function* fetchTokensInfo() {
  while (true) {
    const sdk: CarbonSDK = yield call(waitforSDK)
    const allTokens = {
      ...sdk.token.tokens,
    }
    yield put(updateTokenInfo(allTokens))
    yield delay(STATS_REFRESH_RATE)
  }
}

function* connectWithLocalStorage(wallet: WalletNames | null, side: "sender" | "receiver", senderChain: ChainNames, receiverChain: ChainNames) {
  const chain = side === "sender" ? senderChain : receiverChain
  switch (wallet) {
    case WalletNames.Metamask:
      if (chain === ChainNames.CarbonCore) {
        yield connectCarbonMetamask({ type: WalletActionTypes.WALLET_CONNECT_CARBON_METAMASK, payload: { side } })
      } else {
        yield connectMetamask({ type: WalletActionTypes.WALLET_CONNECT_METAMASK, payload: { senderChain, receiverChain, side } })
      }
      break

    case WalletNames.TrustWallet:
      yield connectTrustWallet({ type: WalletActionTypes.WALLET_CONNECT_TRUSTWALLET, payload: { senderChain, receiverChain, side } })
      break

    case WalletNames.Ledger:
      if (chain === ChainNames.Eth) {
        yield connectEthLedger({ type: WalletActionTypes.WALLET_CONNECT_ETH_LEDGER, payload: { senderChain, receiverChain, side } })
      } else {
        yield connectCosmosLedger({ type: WalletActionTypes.WALLET_CONNECT_COSMOS_LEDGER, payload: { side } })
      }
      break

    case WalletNames.Keplr:
      yield connectKeplr({ type: WalletActionTypes.WALLET_CONNECT_KEPLR, payload: { side } })
      break

    case WalletNames.Leap:
      yield connectLeap({ type: WalletActionTypes.WALLET_CONNECT_LEAP, payload: { side } })
      break

    case WalletNames.ZilPay:
      yield connectZilPay({ type: WalletActionTypes.WALLET_CONNECT_ZIL, payload: { side } })
      break

    case WalletNames.BoltX:
      yield connectBoltX({ type: WalletActionTypes.WALLET_CONNECT_BOLTX, payload: { side } })
      break

    case WalletNames.NeoLine:
      yield connectNeoline({ type: WalletActionTypes.WALLET_CONNECT_NEOLINE, payload: { side, chain } })
      break

    case WalletNames.O3:
      yield connectO3({ type: WalletActionTypes.WALLET_CONNECT_O3, payload: { side, chain } })
      break

    default:
      break
  }
}

function* checkLocalStorage() {
  yield put(updateInitWalletAsync({ loading: true }))
  const senderChain = localStorage.getItem(BridgeLocalStorageKeys.senderChain) as ChainNames ?? ChainNames.Eth
  const receiverChain = localStorage.getItem(BridgeLocalStorageKeys.receiverChain) as ChainNames ?? ChainNames.Bsc
  const senderWallet = localStorage.getItem(BridgeLocalStorageKeys.senderWallet) as WalletNames | null
  const receiverWallet = localStorage.getItem(BridgeLocalStorageKeys.receiverWallet) as WalletNames | null
  yield put(updateChain({ senderChain, receiverChain }))
  yield all([
    call(connectWithLocalStorage, senderWallet, "sender", senderChain, receiverChain),
    call(connectWithLocalStorage, receiverWallet, "receiver", senderChain, receiverChain)
  ])
  yield put(updateInitWalletAsync({ loading: false }))
}

function* initWallet({ payload }: ReturnType<typeof initWalletAction>) {
  yield put(updateInitWalletAsync({ error: null, loading: true }))
  const { side, chain } = payload
  const walletState: BridgeSliceSchema = yield selectState(state => state.wallet)
  const { eth, neo, zil, senderCarbonWallet, receiverCarbonWallet } = walletState
  const carbonWallet = side === "sender" ? senderCarbonWallet : receiverCarbonWallet
  const walletSide = side === "sender" ? "senderWallet" : "receiverWallet"
  let addressObj: { [key: string]: string | WalletNames | null | undefined } = {}
  addressObj[side] = null
  addressObj[walletSide] = null

  try {
    if (isEvm(chain)) {
      if (eth.provider && eth.provider.provider.isMetaMask) {
        const provider = eth.provider.provider as MetamaskProvider
        const address = provider.selectedAddress
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Metamask
        if (side === "sender") { yield checkMetamaskNetwork(eth.provider, chain) }
      } else if (chain === ChainNames.Bsc && side === "sender") {
        yield put(updateBalance({ senderBalance: BN_ZERO, receiverBalance: BN_ZERO }))
        addressObj["sender"] = addressObj["receiver"] = addressObj["senderWallet"] = addressObj["receiverWallet"] = null
      } else if (eth.provider && (eth.provider.provider as any).isWalletConnect) {
        const provider = eth.provider.provider as WalletConnectProvider
        const address = provider.accounts[0]
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.TrustWallet
      } else if (eth.ledger) {
        const address = eth.ledger.displayAddress
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Ledger
      }
    } else if (chain === ChainNames.CarbonCore && carbonWallet) { // TOCHECK
      const address = carbonWallet.bech32Address
      if (carbonWallet.providerAgent === "keplr-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Keplr
      } else if (carbonWallet.providerAgent === "leap-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Leap
      } else if (carbonWallet.providerAgent === "metamask-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Metamask
      } else {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Ledger
      }
    } else if (chain === ChainNames.CarbonEVM && carbonWallet) { // ???
      const address = carbonWallet.evmHexAddress
      if (carbonWallet.providerAgent === "keplr-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Keplr
      } else if (carbonWallet.providerAgent === "leap-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Leap
      } else if (carbonWallet.providerAgent === "metamask-extension") {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Metamask
      } else {
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Ledger
      }
    } else if (chain === ChainNames.Zil) {
      if (zil.zilPay) {
        const address = zil.zilPay.getAPI()?.wallet.defaultAccount?.bech32
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.ZilPay
      } else if (zil.boltX) {
        const boltx = zil.boltX
        const address = boltx.zilliqa.wallet.defaultAccount.bech32
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.BoltX
      }
    } else if (chain === ChainNames.Neo || chain === ChainNames.Neo3) {
      const correctNeoChain = neoChainMap.get(chain)
      if (neo.neoline) {
        const { address } = yield neo.neoline.getAccount()
        const { defaultNetwork } = yield neo.neoline.getNetworks()
        if (defaultNetwork !== correctNeoChain) {
          throw new Error(`Please connect to ${correctNeoChain} on Neoline`)
        }
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.NeoLine
      } else if (neo.o3Wallet) {
        if (neo.o3Wallet.neoNetwork !== correctNeoChain) {
          throw new Error(`Please connect to ${correctNeoChain} on O3`)
        }
        const address = neo.o3Wallet.address
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.O3
      } else if (neo.ledger) {
        const address = neo.ledger.displayAddress
        addressObj[side] = address
        addressObj[walletSide] = WalletNames.Ledger
      }
    }
    yield put(updateAddress(addressObj))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error, loading: false }))
  } finally {
    yield put(updateInitWalletAsync({ loading: false }))
  }
}

function* connectMetamask({ payload }: ReturnType<typeof connectMetamaskAction>) {
  const { side, senderChain, receiverChain } = payload
  try {
    if (!window.ethereum) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noMetaMask }))
      return
    }
    const walletProvider = new ethers.providers.Web3Provider(window.ethereum)
    yield walletProvider.send("eth_requestAccounts", [])
    const metamaskProvider = walletProvider.provider as MetamaskProvider
    const address = metamaskProvider.selectedAddress
    const walletName = WalletNames.Metamask
    yield put(updateEth({ provider: walletProvider }))

    if (isEvm(senderChain, receiverChain)) {
      yield checkMetamaskNetwork(walletProvider, senderChain)
      yield put(updateAddress({ sender: address, receiver: address, senderWallet: walletName, receiverWallet: walletName }))
    } else if (side === "sender") {
      yield checkMetamaskNetwork(walletProvider, senderChain)
      yield put(updateAddress({ sender: address, senderWallet: walletName }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: walletName }))
    }

    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* checkMetamaskNetwork(walletProvider: ethers.providers.Web3Provider, chain: ChainNames) {
  const providerNetwork: ethers.providers.Network = yield walletProvider.getNetwork()
  const chainId = providerNetwork.chainId
  const network: CarbonSDK.Network = yield selectState(state => state.app.network)
  const networkMap: Map<ChainNames, Network> = getNetworkMap(network)
  const correctNetwork = networkMap.get(chain) as Network

  if (ChainID[correctNetwork] !== chainId) {
    yield put(updateBridgeUI({
      statusMessage: warningContent.wrongNetwork,
    }))
    yield put(updatePopup({
      buttonText: `Switch to ${ChainParams[correctNetwork].chainName}`,
      purpose: PopupPurposes.WRONG_CHAIN,
      transactionLink: ""
    }))
  } else {
    yield put(updateBridgeUI({
      statusMessage: null,
    }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
  }
}

function* connectTrustWallet({ payload }: ReturnType<typeof connectTrustWalletAction>) {
  const { side, senderChain, receiverChain } = payload
  try {
    const network: CarbonSDK.Network = yield selectState(state => state.app.network)
    if (network !== CarbonSDK.Network.MainNet) {
      throw new Error("Network unsupported")
    }
    const rpcUrls = Object.values(RpcUrl)
    const chainIDs = Object.values(ChainID)
    const rpcObj: { [key: number]: string } = {}
    chainIDs.forEach((id, i) => {
      rpcObj[id] = rpcUrls[i]
    })
    const provider = new WalletConnectProvider({
      rpc: rpcObj
    })
    yield provider.enable()
    const walletProvider = new ethers.providers.Web3Provider(provider)
    const trustWalletProvider = walletProvider.provider as TrustWalletProvider
    const address = trustWalletProvider.accounts[0]
    const walletName = WalletNames.TrustWallet
    yield put(updateEth({ provider: walletProvider }))
    if (isEvm(senderChain, receiverChain)) {
      yield put(updateAddress({ sender: address, receiver: address, senderWallet: walletName, receiverWallet: walletName }))
    } else if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: walletName }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: walletName }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* connectCarbonMetamask({ payload }: ReturnType<typeof connectCarbonMetamaskAction>) {
  const { side, isCarbonEvm } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  const network: CarbonSDK.Network = yield selectState(state => state.app.network)
  try {
    if (!window.ethereum) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noMetaMask }))
      return
    }
    const metamaskInstance = new MetaMask(network)
    const connectedSDK: CarbonSDK = yield sdk.connectWithMetamask(metamaskInstance)
    yield put(updateCarbonSDK({ carbonSDK: connectedSDK }))
    const address = isCarbonEvm ? connectedSDK.wallet?.evmHexAddress : connectedSDK.wallet?.bech32Address
    if (side === "sender") {
      yield updateChain({ senderChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore })
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.Metamask }))
    } else {
      yield updateChain({ receiverChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore })
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.Metamask }))
    }
    yield connectOppositeSideWallet(isCarbonEvm ?? false, side, WalletNames.Metamask, connectedSDK)
    yield put(updateCarbonWallet({ carbonWallet: sdk.wallet ?? null, side }))
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
    throw rawError
  }
}

function* connectEthLedger({ payload }: ReturnType<typeof connectEthLedgerAction>) {
  const { side, senderChain, receiverChain } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  try {

    // initiate connection to force chrome permission flow
    // because NeoLedgerAccount.connect does not trigger
    // permission flow. if this block is not called
    // NeoLedgerAccount.connect will simply throw USB connect
    // error.
    try {
      yield new CosmosLedger(
        {},
        AddressUtils.SWTHAddress.keyDerivationPath(),
        AddressUtils.SWTHAddress.getBech32Prefix(sdk.network),
      ).connect()
    } catch (e) {
      // ignore error
    }

    const provider: EthLedgerAccount = yield EthLedgerAccount.connect()
    const address = provider.displayAddress
    const walletName = WalletNames.Ledger
    yield put(updateEth({ ledger: provider }))
    if (isEvm(senderChain, receiverChain)) {
      yield put(updateAddress({ sender: address, receiver: address, senderWallet: walletName, receiverWallet: walletName }))
    } else if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: walletName }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: walletName }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* connectKeplr({ payload }: ReturnType<typeof connectKeplrAction>) {
  const { side, isCarbonEvm } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  try {
    if (!(window as any).keplr) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noKeplr }))
      return
    }
    const keplr = (window as KeplrWindow).keplr
    const chainInfo: ChainInfo = yield KeplrAccount.getChainInfo(sdk)
    yield keplr.experimentalSuggestChain(chainInfo)
    yield keplr.enable(chainInfo.chainId)
    const account: Key = yield keplr.getKey(chainInfo.chainId)
    const keplrSigner = KeplrAccount.createKeplrSigner(keplr, chainInfo, account)
    const pubKeyBase64 = Buffer.from(account.pubKey).toString('base64')
    const connectedSDK: CarbonSDK = yield sdk.connectWithSigner(
      keplrSigner,
      pubKeyBase64,
      {
        providerAgent: "keplr-extension",
      }
    )
    yield put(updateCarbonSDK({ carbonSDK: connectedSDK }))
    const address = isCarbonEvm ? connectedSDK.wallet?.evmHexAddress : connectedSDK.wallet?.bech32Address
    if (side === "sender") {
      yield updateChain({ senderChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore }) // update chain for staking page
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.Keplr }))
    } else {
      yield updateChain({ receiverChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore }) // update chain for staking page
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.Keplr }))
    }
    yield connectOppositeSideWallet(isCarbonEvm ?? false, side, WalletNames.Keplr, connectedSDK)
    yield put(updateCarbonWallet({ carbonWallet: sdk.wallet ?? null, side }))
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
    throw rawError
  }
}

function* connectLeap({ payload }: ReturnType<typeof connectLeapAction>) {
  const { side, isCarbonEvm } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  try {
    if (!(window as any).leap) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noLeap }))
      return
    }
    const leap = (window as LeapWindow).leap!
    const chainInfo: ChainInfo = yield LeapAccount.getChainInfo(sdk)
    yield leap.experimentalSuggestChain(chainInfo)
    yield leap.enable(chainInfo.chainId)
    const account: Key = yield leap.getKey(chainInfo.chainId)
    const leapSigner = LeapAccount.createLeapSigner(leap, chainInfo.chainId)
    const pubKeyBase64 = Buffer.from(account.pubKey).toString('base64')
    const connectedSDK: CarbonSDK = yield sdk.connectWithSigner(
      leapSigner,
      pubKeyBase64,
      {
        providerAgent: "leap-extension",
      }
    )
    yield put(updateCarbonWallet({ carbonWallet: sdk.wallet ?? null, side }))
    yield put(updateCarbonSDK({ carbonSDK: connectedSDK }))
    const address = isCarbonEvm ? connectedSDK.wallet?.evmHexAddress : connectedSDK.wallet?.bech32Address
    if (side === "sender") {
      yield updateChain({ senderChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore }) // update chain for staking page
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.Leap }))
    } else {
      yield updateChain({ receiverChain: isCarbonEvm ? ChainNames.CarbonEVM : ChainNames.CarbonCore }) // update chain for staking page
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.Leap }))
    }
    yield connectOppositeSideWallet(isCarbonEvm ?? false, side, WalletNames.Leap, connectedSDK)
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
    throw rawError
  }
}

function* connectCosmosLedger({ payload }: ReturnType<typeof connectCosmosLedgerAction>) {
  const { side, isCarbonEvm } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  try {
    const ledger: CosmosLedger = yield call(connectLedger, sdk.network)
    const connectedSDK: CarbonSDK = (yield call([sdk, sdk.connectWithLedger], ledger)) as CarbonSDK
    yield put(updateCarbonSDK({ carbonSDK: connectedSDK }))
    const address = isCarbonEvm ? connectedSDK.wallet?.evmHexAddress : connectedSDK.wallet?.bech32Address
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.Ledger }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.Ledger }))
    }
    yield connectOppositeSideWallet(isCarbonEvm ?? false, side, WalletNames.Ledger, connectedSDK)
    yield put(updateCarbonWallet({ carbonWallet: sdk.wallet ?? null, side }))
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
    throw rawError
  }
}

function* waitForZilPay() {
  while (true) {
    const zilPay = (window as any).zilPay
    if (zilPay) {
      return zilPay
    }
    yield take("*")
  }
}

function* connectZilPay({ payload }: ReturnType<typeof connectZilPayAction>) {
  const { side } = payload
  const network: CarbonSDK.Network = yield selectState(state => state.app.network)
  try {
    const { zilPayExtension } = yield race({
      zilPayExtension: call(waitForZilPay),
      delay: delay(3000)
    })
    if (!zilPayExtension) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noZilpay }))
      return
    }
    const zilpay = new Zilpay(network)
    if (!((yield zilpay.getAPI()?.wallet.connect()) as boolean)) {
      throw new Error("Zil Connection Rejected")
    }
    const address: string | null = yield zilpay.getAPI()?.wallet.defaultAccount?.bech32 ?? null
    yield put(updateZil({ zilPay: zilpay }))
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.ZilPay }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.ZilPay }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* connectBoltX({ payload }: ReturnType<typeof connectBoltXAction>) {
  const { side } = payload
  try {
    if (!(window as any).boltX) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noBoltX }))
      return
    }
    const boltx = (window as any).boltX

    yield boltx.zilliqa.wallet.connect()
    if (!boltx.zilliqa.wallet.isConnected) {
      throw new Error("Failed to connect to BoltX")
    }

    const account = boltx.zilliqa.wallet.defaultAccount
    if (!account) {
      throw new Error("Please sign in to your BoltX account before connecting.")
    }

    const address = account.bech32
    yield put(updateZil({ boltX: boltx }))
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.BoltX }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.BoltX }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* waitForNeoline() {
  while (true) {
    const NEOLine = (window as any).NEOLine
    if (NEOLine) {
      return NEOLine
    }
    yield take("*")
  }
}

function* connectNeoline({ payload }: ReturnType<typeof connectNeolineAction>) {
  const { side, chain } = payload
  const correctNeoChain = neoChainMap.get(chain)
  try {
    const network: CarbonSDK.Network = yield selectState(state => state.app.network)
    if (network !== CarbonSDK.Network.MainNet) {
      throw new Error("Network unsupported")
    }
    const { NEOLine } = yield race({
      NEOLine: call(waitForNeoline),
      delay: delay(3000)
    })
    if (!NEOLine) {
      yield put(updateBridgeUI({ statusMessage: warningContent.noNeoline }))
      return
    }
    const neoline = new NEOLine.Init()
    const { address } = yield neoline.getAccount()
    const { defaultNetwork } = yield neoline.getNetworks()
    if (defaultNetwork !== correctNeoChain) {
      throw new Error(`Please connect to ${correctNeoChain} on Neoline`)
    }
    yield put(updateNeo({ neoline }))
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.NeoLine }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.NeoLine }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* connectO3({ payload }: ReturnType<typeof connectO3Action>) {
  const { side, chain } = payload
  const correctO3Chain = neoChainMap.get(chain)
  try {
    const network: CarbonSDK.Network = yield selectState(state => state.app.network)
    // if (network !== CarbonSDK.Network.MainNet) {
    //   throw new Error("Network unsupported")
    // }
    const o3Wallet = O3Wallet.instance({ network })
    const networkOutput: O3Types.GetNetworksOutput = yield o3Wallet.getNetworks()
    o3Wallet.setNetwork(networkOutput.defaultNetwork)
    if (o3Wallet.neoNetwork !== correctO3Chain) {
      throw new Error(`Please connect to ${correctO3Chain} on O3`)
    }
    yield o3Wallet.connectWallet()
    const address: string = o3Wallet.address
    yield put(updateNeo({ o3Wallet }))
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.O3 }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.O3 }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

function* connectNeoLedger({ payload }: ReturnType<typeof connectNeoLedgerAction>) {
  const { side, chain } = payload
  const sdk: CarbonSDK = yield call(waitforSDK)
  try {

    // initiate connection to force chrome permission flow
    // because NeoLedgerAccount.connect does not trigger
    // permission flow. if this block is not called
    // NeoLedgerAccount.connect will simply throw USB connect
    // error.
    try {
      yield new CosmosLedger(
        {},
        AddressUtils.SWTHAddress.keyDerivationPath(),
        AddressUtils.SWTHAddress.getBech32Prefix(sdk.network),
      ).connect()
    } catch (e) {
      // ignore error
    }

    const provider: NeoLedgerAccount = yield NeoLedgerAccount.connect(chain === ChainNames.Neo3 ? "n3" : "neo")
    const address = provider.displayAddress
    if (side === "sender") {
      yield put(updateAddress({ sender: address, senderWallet: WalletNames.Ledger }))
    } else {
      yield put(updateAddress({ receiver: address, receiverWallet: WalletNames.Ledger }))
    }
    yield put(updateInitWalletAsync({ error: null }))
  } catch (rawError) {
    const error = parseError(rawError)
    yield put(updateInitWalletAsync({ error }))
  }
}

// Remove this function and all occurrences to allow selection of different wallets to bridge between Carbon Core <> Carbon EVM
function* connectOppositeSideWallet(isCarbonEvm: boolean, side: "sender" | "receiver", walletName: WalletNames, connectedSDK: CarbonSDK) {
  const tokenDenom = (yield select(state => state.wallet.tokenDenom)) as string
  const isCarbonEvmBridge = tokenDenom !== "swth"

  if (isCarbonEvmBridge) {
    const otherSide = side === "sender" ? "receiver" : "sender"
    const sideKey = otherSide === "sender" ? "senderWallet" : "receiverWallet"
    const address = !isCarbonEvm ? connectedSDK.wallet?.evmHexAddress : connectedSDK.wallet?.bech32Address

    yield put(updateAddress({ [otherSide]: address, [sideKey]: walletName }))
    yield put(updateCarbonWallet({ carbonWallet: connectedSDK.wallet ?? null, side: otherSide }))
  }
}

function* disconnectWallet({ payload }: ReturnType<typeof disconnectWalletAction>) {
  const { side } = payload
  const walletState: BridgeSliceSchema = yield selectState(state => state.wallet)
  const sdk: CarbonSDK = yield call(waitforSDK)
  const chain = side === "sender" ? walletState.senderChain : walletState.receiverChain
  const tokenDenom = (yield select(state => state.wallet.tokenDenom)) as string
  const isCarbonEvmBridge = tokenDenom !== "swth"

  yield put(updateBridgeUI({ input: { amount: "", percentage: 0 } }))
  if (isEvm(walletState.senderChain, walletState.receiverChain)) {
    yield put(updateAddress({ sender: null, senderWallet: null, receiver: null, receiverWallet: null }))
  } else if (isCarbonEvmBridge) { //Remove when trying to allow selection of different wallets to bridge between Carbon Core <> Carbon EVM 
    yield put(updateAddress({ sender: null, senderWallet: null, receiver: null, receiverWallet: null }))
    yield put(updateCarbonWallet({ carbonWallet: null, side: "receiver" }))
    yield put(updateCarbonWallet({ carbonWallet: null, side: "sender" }))
  } else if (side === "sender") {
    yield put(updateAddress({ sender: null, senderWallet: null }))
  } else {
    yield put(updateAddress({ receiver: null, receiverWallet: null }))
  }

  yield put(updateCarbonWallet({ carbonWallet: null, side }))

  if (isEvm(chain)) {
    if (walletState.eth.provider) {
      yield put(updateEth({ provider: null }))
    } else if (walletState.eth.ledger) {
      yield put(updateEth({ ledger: null }))
    }
  } else if (chain === ChainNames.CarbonCore && sdk) {
    if (sdk.wallet) {
      const disconnectedSDK = sdk.disconnect()
      yield put(resetStakeState())
      yield put(updateCarbonSDK({ carbonSDK: disconnectedSDK }))
    }
  } else if (chain === ChainNames.Zil) {
    if (walletState.zil.zilPay) {
      yield put(updateZil({ zilPay: null }))
    } else if (walletState.zil.boltX) {
      yield put(updateZil({ boltX: null }))
    }
  } else if (chain === ChainNames.Neo) {
    if (walletState.neo.o3Wallet) {
      yield put(updateNeo({ o3Wallet: null }))
    } else if (walletState.neo.neoline) {
      yield put(updateNeo({ neoline: null }))
    } else if (walletState.neo.ledger) {
      yield put(updateNeo({ ledger: null }))
    }
  }
}

function* changeNetwork() {
  yield put(updateChangeNetworkAsync({ error: null }))
  const chain: ChainNames = yield selectState(state => state.wallet.senderChain)
  const network: CarbonSDK.Network = yield selectState(state => state.app.network)
  const networkMap = getNetworkMap(network)
  const chainName = networkMap.get(chain) as Network
  try {
    if (!window.ethereum) {
      throw new Error("No crypto wallet found")
    }
    yield put(updateChangeNetworkAsync({ loading: true }))
    yield window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{
        chainId: `0x${ChainID[chainName].toString(16)}`,
      }]
    })
    yield put(updatePopup({ buttonText: "", purpose: null, transactionLink: "" }))
  } catch (switchError: any) {
    if (switchError?.code === 4902) {
      yield window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [ChainParams[chainName]],
      })
    } else {
      const error = parseError(switchError)
      yield put(updateChangeNetworkAsync({ error }))
    }
  } finally {
    yield put(updateChangeNetworkAsync({ loading: false }))
  }
}

function* approveAllowance({ payload }: ReturnType<typeof approveAllowanceAction>) {
  yield put(updateApproveAllowanceAsync({ error: null, loading: true }))
  const { walletProviderSender, contractAddr, senderChain, purpose, token, reloadAllowance } = payload
  const network: CarbonSDK.Network = yield selectState(state => state.app.network)
  let address: string = ""
  let tx: ethers.Transaction | Transaction | undefined
  let error: Error | null = null
  let gasPrice: BigNumber

  yield put(updatePopup({ purpose: PopupPurposes.BEF_APPROVE, transactionLink: "", buttonText: "" }))
  try {
    const sdk: CarbonSDK = yield call(waitforSDK)
    if (!sdk) {
      throw new Error("CarbonSDK Initialisation failed")
    }

    if (isEvm(senderChain)) {
      let signer: ethers.providers.JsonRpcSigner | EthLedgerSigner
      const ethClient: ETHClient = getETHClient(sdk, senderChain, network)
      let rpcProvider = ethClient.getProvider()
      if ((walletProviderSender as ethers.providers.Web3Provider)._isProvider) {
        const walletProvider = walletProviderSender as ethers.providers.Web3Provider
        rpcProvider = walletProvider
        signer = walletProvider.getSigner()
        address = yield signer.getAddress()
        gasPrice = yield rpcProvider.getGasPrice()
      } else {
        const ledgerProvider = walletProviderSender as EthLedgerAccount
        address = ledgerProvider.displayAddress
        signer = new EthLedgerSigner(rpcProvider, ledgerProvider)
        gasPrice = yield rpcProvider.getGasPrice()
      };

      const gasPriceGwei = parseNumber(gasPrice?.toString(), BN_ZERO)!.shiftedBy(-9)
      tx = yield ethClient.approveERC20({
        gasPriceGwei,
        gasLimit: getEvmGasLimit(senderChain, "approve") ?? new BigNumber(100000),
        ethAddress: address,
        signer,
        token,
        spenderAddress: contractAddr
      })

      yield put(updatePopup({ purpose: PopupPurposes.AFT_APPROVE_APPROVAL, transactionLink: transactionToUrl(tx!.hash as string, senderChain, network), buttonText: "" }))
      yield rpcProvider.waitForTransaction(tx!.hash as string)
      const txnReceipt: ethers.providers.TransactionReceipt = yield rpcProvider.getTransactionReceipt(tx!.hash as string)
      if (txnReceipt.status === 0) {
        throw new Error("Transaction failed")
      }
    } else if (senderChain === ChainNames.Zil) {
      if (!(walletProviderSender instanceof Zilpay)) {
        throw new Error("Sender Provider not supported")
      }
      const signer: WalletProvider | undefined = yield (walletProviderSender).getAPI()
      if (!signer) {
        throw new Error("Signer unable to be instantiated")
      }
      address = yield signer.wallet.defaultAccount?.bech32
      if (!address) {
        throw new Error("Sender Address cannot be found")
      }
      const rpcResponse: ZilRPCResponse = yield signer.blockchain.getMinimumGasPrice()
      const minGasPrice: string = rpcResponse.result as string

      tx = yield sdk.zil.approveZRC2({
        gasPrice: new BigNumber(minGasPrice),
        gasLimit: ZIL_APPROVE_GAS_LIMIT,
        zilAddress: address,
        spenderAddress: contractAddr,
        signer: signer as any,
        token,
      })
      const zilTransaction = tx as Transaction
      yield put(updatePopup({ purpose: PopupPurposes.AFT_APPROVE_APPROVAL, transactionLink: transactionToUrl(zilTransaction.id as string, senderChain, network), buttonText: "" }))
      const { txSuccess } = yield race({
        txSuccess: call(watchZilTxConfirmation, zilTransaction.id!),
        delay: delay(ZIL_BRIDGE_TIMEOUT)
      })
      if (!txSuccess) {
        throw new Error("Transaction failed")
      }
    } else {
      throw new Error("Source chain not yet supported.")
    }
    if (purpose === "bridge") {
      yield put(updateBridgeUI({ button: { text: "Approved" } }))
    } else if (purpose === "migrate") {
      yield put(updateMigrateUI({ button: { text: "Approved" } }))
    }
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
    reloadAllowance()
  } catch (rawError) {
    error = parseError(rawError)
    yield put(updateApproveAllowanceAsync({ error, loading: false }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
  } finally {
    let transactionHash: string | undefined
    if (isEvm(senderChain)) {
      transactionHash = (tx as ethers.Transaction).hash
    } else if (senderChain === ChainNames.Zil) {
      transactionHash = (tx as Transaction).id
    }
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress: address,
      senderChain,
      receiverAddress: appendHexPrefix(token.tokenAddress),
      receiverChain: senderChain,
      transactionHash: error ? null : transactionHash as string,
      error
    }))
    yield put(updateApproveAllowanceAsync({ loading: false }))
  }
}

function* bridgeEvmTokens({ payload }: ReturnType<typeof bridgeEvmTokenAction>) {
  const { senderChain, receiverChain, fromToken, toToken, amount, recoveryAddress, receiverAddress, walletProviderSender, feeAmount, reloadBalance, reloadAllowance, setAllowance, signCompleteCallback } = payload

  let signer: ethers.providers.JsonRpcSigner | EthLedgerSigner
  let gasPrice: BigNumber
  let senderAddress: string = ""
  let error: Error | null = null
  let bridgeTx: ethers.Transaction | undefined

  try {
    const sdk: CarbonSDK = yield call(waitforSDK)
    if (!sdk) {
      throw new Error("CarbonSDK Initialisation failed")
    }

    const network: CarbonSDK.Network = yield selectState(state => state.app.network)
    const ethClient: ETHClient = getETHClient(sdk, senderChain, network)
    const decimals = fromToken.decimals.toNumber()
    const bridgeAmount = amount.shiftedBy(decimals)

    let rpcProvider = ethClient.getProvider()
    if ((walletProviderSender as ethers.providers.Web3Provider)._isProvider) {
      const walletProvider = walletProviderSender as ethers.providers.Web3Provider
      rpcProvider = walletProvider
      signer = walletProvider.getSigner()
      senderAddress = yield signer.getAddress()
      gasPrice = yield walletProvider.getGasPrice()
    } else {
      const ledgerProvider = walletProviderSender as EthLedgerAccount
      gasPrice = yield rpcProvider.getGasPrice()
      senderAddress = ledgerProvider.displayAddress
      signer = new EthLedgerSigner(rpcProvider, ledgerProvider)
    }

    const gasPriceGwei = parseNumber(gasPrice?.toString(), BN_ZERO)!.shiftedBy(-9)

    yield put(updatePopup({ purpose: PopupPurposes.BEF_APPROVE, buttonText: "", transactionLink: "" }))
    if (receiverChain === ChainNames.CarbonCore) { // To Carbon
      const addressBytes = AddressUtils.SWTHAddress.getAddressBytes(receiverAddress, sdk.network)
      bridgeTx = yield ethClient.lockDeposit({
        address: addressBytes, // addressbytes of receiver
        amount: bridgeAmount, // amount to lock
        ethAddress: senderAddress, // address of sender
        gasLimit: getEvmGasLimit(senderChain, "deposit") ?? new BigNumber(900000), // gas limit
        gasPriceGwei, // gasprice
        signer, // signer of sender
        token: fromToken, // from token
        signCompleteCallback // callback upon sign complete
      })
    } else { // to other chains
      let toAddress: string
      if (receiverChain === ChainNames.Zil) {
        toAddress = fromBech32Address(receiverAddress)
      } else {
        toAddress = receiverAddress
      }
      bridgeTx = yield ethClient.bridgeTokens({
        fromToken, // from token
        toToken, // to token
        amount: bridgeAmount, // amount to transfer
        recoveryAddress, // recovery address swth
        gasPriceGwei, // gasprice
        gasLimit: getEvmGasLimit(senderChain, "bridge") ?? new BigNumber(1000000), // gas limit
        toAddress, // receiverAddress
        fromAddress: senderAddress, // senderAddress
        signer, // signer of sender
        feeAmount, //: new BigNumber(0), // fee amount
        signCompleteCallback // callback upon sign complete 
      })
    }
    yield put(updateBridgeUI({ bridgeProcess: { success: false, popup: true, transactionLink: transactionToUrl(bridgeTx!.hash as string, senderChain, sdk.network) } }))
    setAllowance((prevAllowance) => {
      return prevAllowance.minus(bridgeAmount)
    })
    yield rpcProvider.waitForTransaction(bridgeTx!.hash as string)
    const txnReceipt: ethers.providers.TransactionReceipt = yield rpcProvider.getTransactionReceipt(bridgeTx!.hash as string)
    if (txnReceipt.status === 0) {
      throw new Error("Transaction failed")
    }
    yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
    reloadBalance("sender")
    reloadBalance("receiver")
    reloadAllowance()
  } catch (rawError) {
    error = parseError(rawError)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
    yield put(updateBridgeAsync({ error }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress,
      senderChain,
      receiverAddress: receiverAddress,
      receiverChain,
      transactionHash: error ? null : bridgeTx!.hash as string,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}

function* bridgeCarbonTokens({ payload }: ReturnType<typeof bridgeCarbonTokenAction>) {
  const { receiverChain, receiverAddress, toToken, amount, feeAddress, feeAmount, reloadAllowance, reloadBalance } = payload
  yield put(updateBridgeUI({ bridgeProcess: { success: false, popup: true } }))
  const connectedSDK: CarbonSDK = yield call(waitforSDK)
  // const decimals = toToken.decimals
  // const shiftedFeeAmount = feeAmount.shiftedBy(-decimals)

  let result: { transactionHash: string } | undefined
  let error: Error | null = null

  try {
    if (isEvm(receiverChain)) { // To EVM
      if (!connectedSDK) {
        throw new Error("CarbonSDK Initialisation failed")
      }
      const ethClient: ETHClient = getETHClient(connectedSDK, receiverChain, connectedSDK.network)
      const formattedAddress: string = yield ethClient.formatWithdrawalAddress(receiverAddress)
      result = yield connectedSDK.coin.createWithdrawal({
        denom: toToken.denom,
        toAddress: formattedAddress,
        amount: connectedSDK.token.toUnitless(toToken.denom, amount) ?? BN_ZERO,
        feeAmount,
        feeAddress,
        feeDenom: toToken.denom,
      })
      yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
      reloadBalance("sender")
      reloadBalance("receiver")
      reloadAllowance()
    } else if (receiverChain === ChainNames.Zil) {
      const formattedAddress: string = yield connectedSDK.zil.formatWithdrawalAddress(receiverAddress)
      result = yield connectedSDK.coin.createWithdrawal({
        denom: toToken.denom,
        toAddress: formattedAddress,
        amount: connectedSDK.token.toUnitless(toToken.denom, amount) ?? BN_ZERO,
        feeAmount,
        feeAddress,
        feeDenom: toToken.denom,
      })
      yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
      reloadBalance("sender")
      reloadBalance("receiver")
      reloadAllowance()
    } else {
      throw new Error("Destination chain not yet supported.")
    }
  } catch (rawError) {
    error = parseError(rawError)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updateBridgeAsync({ error }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress: connectedSDK.wallet?.bech32Address ?? "",
      senderChain: ChainNames.CarbonCore,
      receiverAddress: receiverAddress,
      receiverChain,
      transactionHash: error ? null : result!.transactionHash,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}


// Carbon Core <> Carbon EVM Bridge
function* convertEvmTokens({ payload }: ReturnType<typeof convertEVMTokensAction>) {
  const connectedSDK: CarbonSDK = yield call(waitforSDK)
  const { reloadBalance, senderAddress, senderWalletProviderAgent, ...convertErc20Params } = payload
  let result: DeliverTxResponse | null = null
  let error: Error | null = null

  try {
    // Reconnect wallet, If not SDK wallet will be the latest connected wallet instead of the sender wallet
    yield reconnectWallet(senderWalletProviderAgent, true)
  } catch (e) {
    console.error(e)
    return
  }

  try {
    yield put(updateBridgeUI({ bridgeProcess: { success: false, popup: true } }))
    result = yield connectedSDK.erc20.convertERC20(convertErc20Params, { feeDenom: "swth" })
    yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
    reloadBalance("receiver")
    reloadBalance("sender")
  } catch (e) {
    error = parseError(e)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
    yield put(updateBridgeAsync({ error }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress: payload.senderAddress ?? "",
      senderChain: ChainNames.CarbonEVM,
      receiverAddress: payload.receiverAddress ?? "",
      receiverChain: ChainNames.CarbonCore,
      transactionHash: error ? null : result!.transactionHash,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}

// Carbon Core <> Carbon EVM Bridge
function* convertCarbonTokens({ payload }: ReturnType<typeof convertCarbonTokensAction>) {
  const connectedSDK: CarbonSDK = yield call(waitforSDK)
  const { reloadBalance, senderAddress, senderWalletProviderAgent, ...convertErc20Params } = payload
  let result: DeliverTxResponse | null = null
  let error: Error | null = null

  try {
    // Reconnect wallet, If not SDK wallet will be the latest connected wallet instead of the sender wallet
    yield reconnectWallet(senderWalletProviderAgent, false)
  } catch (e) {
    console.error(e)
    return
  }

  try {
    yield put(updateBridgeUI({ bridgeProcess: { success: false, popup: true } }))
    result = yield connectedSDK.erc20.convertCoin(convertErc20Params, { feeDenom: "swth" })
    yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
    reloadBalance("receiver")
    reloadBalance("sender")

  } catch (e) {
    error = parseError(e)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
    yield put(updateBridgeAsync({ error }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress: payload.senderAddress ?? "",
      senderChain: ChainNames.CarbonCore,
      receiverAddress: payload.receiverAddress ?? "",
      receiverChain: ChainNames.CarbonEVM,
      transactionHash: error ? null : result!.transactionHash,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}

function* reconnectWallet(senderWalletProviderAgent: string, isCarbonEvm: boolean) {
  try {
    switch (senderWalletProviderAgent) {
      case 'metamask-extension':
        yield connectCarbonMetamask({ type: WalletActionTypes.WALLET_CONNECT_CARBON_METAMASK, payload: { side: "sender", isCarbonEvm } })
        break
      case 'keplr-extension':
        yield connectKeplr({ type: WalletActionTypes.WALLET_CONNECT_KEPLR, payload: { side: "sender", isCarbonEvm } })
        break
      case 'leap-extension':
        yield connectLeap({ type: WalletActionTypes.WALLET_CONNECT_LEAP, payload: { side: "sender", isCarbonEvm } })
        break
      case 'ledger-extension':
        yield connectCosmosLedger({ type: WalletActionTypes.WALLET_CONNECT_COSMOS_LEDGER, payload: { side: "sender", isCarbonEvm } })
        break
      default:
        return
    }
  } catch (e) {
    throw e
  }
}

function* watchZilTxConfirmation(hash: string) {
  const sdk: CarbonSDK = yield call(waitforSDK)
  const zilliqa = new Zilliqa(sdk.zil.getProviderUrl())
  while (true) {
    try {
      const sourceTx: Transaction = yield zilliqa.blockchain.getTransaction(hash)
      const receipt = sourceTx.getReceipt()
      const success = receipt?.success
      return success
    } catch (error: any) {
      if (error.code !== -20) {
        console.error(error)
      }
    } finally {
      delay(ZIL_BRIDGE_WATCH_DELAY)
    }
  }
}

function* bridgeZilTokens({ payload }: ReturnType<typeof bridgeZilTokenAction>) {
  const { receiverChain, fromToken, toToken, amount, receiverAddress, recoveryAddress, walletProviderSender, feeAmount, reloadAllowance, reloadBalance, setAllowance, signCompleteCallback } = payload
  let error: Error | null = null
  let bridgeTx: Transaction | undefined
  let senderAddress: string | undefined
  try {
    const sdk: CarbonSDK = yield call(waitforSDK)
    if (!sdk) {
      throw new Error("CarbonSDK Initialisation failed")
    }
    const decimals = fromToken.decimals.toNumber()
    const bridgeAmount = amount.shiftedBy(decimals)
    const signer: WalletProvider | undefined = yield (walletProviderSender).getAPI()
    if (!signer) {
      return
    }
    senderAddress = yield signer.wallet.defaultAccount?.bech32
    if (!senderAddress) {
      throw new Error("Sender Address could not be determined")
    }
    const rpcResponse: ZilRPCResponse = yield signer.blockchain.getMinimumGasPrice()
    const minGasPrice = rpcResponse.result as string
    bridgeTx = yield sdk.zil.bridgeTokens({
      fromToken,
      toToken,
      amount: bridgeAmount,
      fromAddress: senderAddress,
      recoveryAddress,
      toAddress: receiverAddress,
      feeAmount,
      gasPrice: new BigNumber(minGasPrice),
      gasLimit: ZIL_BRIDGE_GAS_LIMIT,
      signer,
      signCompleteCallback
    })
    yield put(updateBridgeUI({ bridgeProcess: { success: false, popup: true, transactionLink: transactionToUrl(bridgeTx!.id as string, ChainNames.Zil, sdk.network) } }))
    const { txSuccess } = yield race({
      txSuccess: call(watchZilTxConfirmation, bridgeTx?.id!),
      delay: delay(ZIL_BRIDGE_TIMEOUT)
    })
    if (!txSuccess) {
      throw new Error("Transaction Failed")
    }
    setAllowance((prevAllowance) => {
      return prevAllowance.minus(bridgeAmount)
    })
    yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
    reloadBalance("sender")
    reloadBalance("receiver")
    reloadAllowance()
  } catch (rawError) {
    error = parseError(rawError)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
    yield put(updateBridgeAsync({ error }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress: senderAddress ?? "",
      senderChain: ChainNames.Zil,
      receiverAddress: receiverAddress,
      receiverChain,
      transactionHash: error ? null : bridgeTx!.id as string,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}

function* bridgeNeoTokens({ payload }: ReturnType<typeof bridgeNeoTokenAction>) {
  const { receiverChain, fromToken, amount, receiverAddress, o3Wallet, reloadAllowance, reloadBalance, setAllowance } = payload
  const senderAddress: string = o3Wallet.address
  let error: Error | null = null
  let bridgeTx: ethers.Transaction | undefined
  const fromTokenWithBalance: TokensWithExternalBalance = { ...fromToken, externalBalance: "" }
  yield put(updatePopup({ purpose: PopupPurposes.BEF_APPROVE, buttonText: "", transactionLink: "" }))
  try {
    const sdk: CarbonSDK = yield call(waitforSDK)
    if (!sdk) {
      throw new Error("CarbonSDK Initialisation failed")
    }
    const decimals = fromToken.decimals.toNumber()
    const bridgeAmount = ethers.utils.parseUnits(amount.toString(), decimals)
    if (receiverChain === ChainNames.CarbonCore) { // To Carbon
      const addressBytes = AddressUtils.SWTHAddress.getAddressBytes(receiverAddress, sdk.network)
      bridgeTx = yield sdk.neo.lockO3Deposit({
        address: addressBytes, // addressbytes of receiver
        amount: new BigNumber(bridgeAmount.toString()), // amount to lock
        token: fromTokenWithBalance, // from token
        feeAmount: new BigNumber(0),
        o3Wallet,
      })
    } else { // to other chains
      //
    }
    setAllowance((prevAllowance) => {
      return prevAllowance.minus(amount.shiftedBy(decimals))
    })
    yield put(updateBridgeUI({ bridgeProcess: { success: true }, button: { text: "Bridged" } }))
    reloadBalance("sender")
    reloadBalance("receiver")
    reloadAllowance()
  } catch (rawError) {
    error = parseError(rawError)
    yield put(updateBridgeUI({ bridgeProcess: { popup: false } }))
    yield put(updateBridgeAsync({ error }))
    yield put(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
  } finally {
    yield put(addToastItem({
      id: uuidv4(),
      senderAddress,
      senderChain: ChainNames.Neo,
      receiverAddress: receiverAddress,
      receiverChain,
      transactionHash: error ? null : bridgeTx!.hash as string,
      error,
    }))
    yield put(updateBridgeAsync({ loading: false }))
  }
}
//TODO: Fix fetch transactions
// function* fetchTransactions(): any {
//   yield runSagaTask(WalletTasks.Transactiona, function* () {
//     const sdk = (yield call(waitforSDK)) as CarbonSDK
//     const miscQueryClient = sdk.query.misc
//     const response = (yield call([miscQueryClient, miscQueryClient.TransactionAll], QueryAllTransactionRequest.fromPartial({
//       pagination: {
//         page: new Long(1),
//         pageSize: new Long(5),
//       },
//     }))) as QueryAllTransactionResponse
//     yield put(updateTransactions({
//       data: response.transactions.sort((a: Transactions, b: Transactions) => b.blockHeight.toNumber() - a.blockHeight.toNumber()).slice(0,7)
//     })
//     )
//     logger("transactions", JSON.stringify(response.transactions.sort((a: Transactions, b: Transactions) => b.blockHeight.toNumber() - a.blockHeight.toNumber()).slice(0,7)))
//   })
// }

function* init() {
  yield checkLocalStorage()
}

export function* bridgeSaga() {
  yield fork(fetchTokensInfo)
  yield fork(init)
  yield takeLatest(WalletActionTypes.WALLET_CHECK_LOCAL_STORAGE, checkLocalStorage)
  yield takeLatest(WalletActionTypes.WALLET_APPROVE_ALLOWANCE, approveAllowance)
  yield takeEvery(WalletActionTypes.WALLET_INIT_WALLET, initWallet)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_METAMASK, connectMetamask)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_CARBON_METAMASK, connectCarbonMetamask)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_TRUSTWALLET, connectTrustWallet)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_KEPLR, connectKeplr)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_LEAP, connectLeap)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_ZIL, connectZilPay)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_BOLTX, connectBoltX)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_NEOLINE, connectNeoline)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_O3, connectO3)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_ETH_LEDGER, connectEthLedger)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_COSMOS_LEDGER, connectCosmosLedger)
  yield takeEvery(WalletActionTypes.WALLET_CONNECT_NEO_LEDGER, connectNeoLedger)
  yield takeEvery(WalletActionTypes.WALLET_CHANGE_NETWORK, changeNetwork)
  yield takeEvery(WalletActionTypes.WALLET_EVM_TOKENS, bridgeEvmTokens)
  yield takeEvery(WalletActionTypes.WALLET_CARBON_TOKENS, bridgeCarbonTokens)
  yield takeEvery(WalletActionTypes.WALLET_ZIL_TOKENS, bridgeZilTokens)
  yield takeEvery(WalletActionTypes.WALLET_NEO_TOKENS, bridgeNeoTokens)
  yield takeEvery(WalletActionTypes.WALLET_DISCONNECT_WALLET, disconnectWallet)
  yield takeEvery(WalletActionTypes.WALLET_CONVERT_CARBON_TOKENS, convertCarbonTokens)
  yield takeEvery(WalletActionTypes.WALLET_CONVERT_EVM_TOKENS, convertEvmTokens)
}
