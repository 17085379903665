import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import Loading_Dark from "assets/Loading_Transparent.gif"
import Refresh from "assets/Refresh.svg"
import RefreshBranding from "assets/Refresh_Branding.svg"
import BigNumber from 'bignumber.js'
import { CarbonTx } from "carbon-js-sdk"
import { BondStatus, Validator } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { SimpleMap } from "carbon-js-sdk/lib/util/type"
import AvatarImage from 'components/Common/AvatarImage'
import DropDown, { DropdownItem } from 'components/Common/DropDown'
import { InputSlider } from 'components/Common/InputSlider'
import Notification from 'components/Common/Notification'
import RedelegatePopup from 'components/Common/Popups/RedelegatePopup'
import CoinIcon from "components/Common/Tokens/CoinIcon"
import WalletButton from 'components/StakePage/DelegationInfo/Common/WalletButton'
import { BN_ZERO } from "constants/math"
import { ChainNames } from 'constants/types'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector, useAsyncTask } from "hooks"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateStakeAsync } from 'store/Async'
import { StakeActionTypes, ValPair, reloadUserDelegations } from "store/Stake"
import { addToastItem } from 'store/Toast'
import { bnOrZero, parseError, parseNumber, reduxAction, uuidv4 } from "utils"
import { ButtonState, FormState } from '../utils/createConfig'
import './RedelegateCard.css'

const initialFormState: FormState = {
  delegateAsset: "swth",
  delegateFrom: null,
  delegateTo: null,

  delegateFromAddr: null,
  delegateToAddr: null,

  input: '0',
  percentage: 0,
}

const initialButtonState: ButtonState = {
  enabled: true,
  text: "",
}


const RedelegateCard: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)
  const [inputError, setInputError] = useState<string>()
  const [hoverRefreshBalance, setHoverRefreshBalance] = useState<boolean>(false)
  const [clickedRefreshBalance, setClickedRefreshBalance] = useState<boolean>(false)
  const [popup, setPopup] = useState<boolean>(false)
  const [runRestake, loadingRestake] = useAsyncTask()
  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [buttonState, setButtonState] = useState<ButtonState>(initialButtonState)
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap) as SimpleMap<ValPair>
  const sdk = useAppSelector(state => state.app.carbonSDK)
  const network = useAppSelector(state => state.app.network)
  const userDelegations = useAppSelector(state => state.stake.userDelegations)
  const chosenValidator = useAppSelector(state => state.ui.stake.chosenValidator)
  const redelegationsArr = useAppSelector(state => state.stake.userRedelegations)
  const allianceAssets = useAppSelector(state => state.stake.allianceAssets)

  const resetFormState = () => setFormState(initialFormState)

  const isLedgerDisabled = useMemo(() => {
    const isLedger = sdk?.wallet?.isLedgerSigner()
    return formState.delegateAsset !== "swth" && isLedger
  }, [sdk?.wallet, formState.delegateAsset]);

  const notificationList = [
    <React.Fragment>You <b>cannot redelegate stake from the new validator for 30 days</b>.</React.Fragment>,
    <React.Fragment>You will be liable for slashing if it is found that the old validator had committed an infraction while your tokens were staked with them.</React.Fragment>,
    <React.Fragment>There is a limit of 6 concurrent redelegations/undelegations per validator.</React.Fragment>,
  ]

  const redelegations: Validator[] = useMemo(() => {
    const results: Validator[] = []

    for (const redelegation of redelegationsArr) {
      if (redelegation === undefined) continue

      const validatorTo = valAddrMap[redelegation.validatorDstAddress].carbonValidator
      const completionTime = redelegation.completionTime ? redelegation.completionTime.toString() : ''
      if (dayjs().isBefore(completionTime)) {
        results.push(validatorTo)
      }
    }
    return results
  }, [redelegationsArr, valAddrMap])


  const validators = useMemo(() => {
    let results = Object.values(valAddrMap).map(pair => pair.carbonValidator).sort((lhs, rhs) => bnOrZero(rhs.tokens).comparedTo(lhs.tokens))
    results = results.filter((each) => each?.status !== BondStatus.BOND_STATUS_UNBONDED).filter(validator => validator?.operatorAddress !== formState.delegateFromAddr)
    return results
  }, [valAddrMap, formState.delegateFromAddr])

  const recomputeState = useCallback((newFormState: Partial<FormState>) => {
    // setSuccess(false)
    // setSuccessResponse(null)
    const newState = {
      ...formState,
      ...newFormState,
    }

    setFormState(newState)
  }, [formState, setFormState])

  useEffect(() => {
    if (sdk?.wallet?.bech32Address) {
      dispatch(reloadUserDelegations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, sdk])

  useEffect(() => {
    recomputeState({
      ...formState,
      delegateFrom: chosenValidator,
      delegateFromAddr: chosenValidator?.operatorAddress
    })
    // eslint-disable-next-line
  }, [chosenValidator])


  const { delegatedOptions, delegatedAddrOptions } = useMemo(() => {
    const valList: SimpleMap<DropdownItem> = {}

    for (const delegation of userDelegations) {
      const validator = valAddrMap[delegation.validatorAddress]?.carbonValidator
      if (redelegations.includes(validator)) continue
      valList[delegation.validatorAddress] = { img: <AvatarImage identity={validator?.description?.identity} />, content: validator?.description?.moniker ?? "" }
    }

    const [delegatedOptions, delegatedAddrOptions] = Object.entries(valList).reduce((result, entry) => {
      const [validatorAddress, dropdown] = entry
      result[0].push(dropdown)
      result[1].push(valAddrMap[validatorAddress].carbonValidator)
      return result
    }, [[], []] as [DropdownItem[], Validator[]])

    return {
      delegatedOptions,
      delegatedAddrOptions,
    }
  }, [userDelegations, redelegations, valAddrMap])

  const { assetOptions, assets } = useMemo(() => {
    const options: DropdownItem[] = [{
      content: "SWTH",
      img: <CoinIcon denom="SWTH" />
    }];
    const assets = ["swth"];

    if (sdk === null)
      return {
        assetOptions: options,
        assets,
      }

    for (const asset of allianceAssets) {
      const token = sdk.token.tokenForDenom(asset.denom)
      if (!token) continue

      assets.push(token.denom);
      options.push({
        content: token.symbol,
        tag: <span className="dropdown-tag">Alliance</span>,
        img: <CoinIcon denom={token.symbol} />
      })
    }

    return {
      assetOptions: options,
      assets,
    }
  }, [allianceAssets, sdk])

  const adjustedFee = useMemo(() => {
    const feeDenom = "swth"
    const txFee = (sdk?.gasFee?.getFee(CarbonTx.TxGasCostTypeDefaultKey, feeDenom) ?? BN_ZERO)
    const feeDenomPrice = sdk?.token.getUSDValue(feeDenom) ?? BN_ZERO
    return {
      denom: feeDenom,
      symbol: sdk?.token.tokenForDenom(feeDenom)?.symbol ?? "",
      amount: sdk?.token.toHuman("swth", txFee) ?? BN_ZERO,
      price: feeDenomPrice,
    }
  }, [sdk?.gasFee, sdk?.token])

  const assetInfo = useMemo(() => {
    const denom = formState.delegateAsset
    const token = sdk?.token.tokenForDenom(denom)
    return {
      decimals: token?.decimals.toNumber() ?? 0,
      symbol: token?.symbol ?? "",
      price: sdk?.token.getUSDValue(denom) ?? BN_ZERO,
    }
  }, [sdk?.token, formState.delegateAsset]);
  const walletAddress = sdk?.wallet?.bech32Address

  const defaultOption = useMemo(() => {
    if (!chosenValidator) return
    for (var i = 0; i < delegatedAddrOptions.length; i++) {
      if (delegatedAddrOptions[i]?.operatorAddress === chosenValidator?.operatorAddress) {
        return i
      }
    }
    return ""
  }, [chosenValidator, delegatedAddrOptions])

  const delegatedAmountMap: SimpleMap<SimpleMap<BigNumber>> = useMemo(() => {
    const returnMap: SimpleMap<SimpleMap<BigNumber>> = {}
    for (const delegation of userDelegations) {
      if (!returnMap[delegation.validatorAddress])
        returnMap[delegation.validatorAddress] = {}
      returnMap[delegation.validatorAddress][delegation.denom] = delegation.balance
    }
    return returnMap
  }, [userDelegations])

  const valOptions: DropdownItem[] = useMemo(() => {
    var valList: DropdownItem[] = []
    validators.forEach((validator) => {
      valList.push({ img: <AvatarImage identity={validator?.description?.identity} />, content: validator?.description?.moniker! })
    })
    return valList
  }, [validators])

  const delegatedBalance = useMemo(() => {
    const delegatedBN = delegatedAmountMap[formState.delegateFromAddr ?? ""]?.[formState.delegateAsset] ?? BN_ZERO
    return delegatedBN.shiftedBy(-assetInfo.decimals) ?? BN_ZERO
  }, [formState.delegateFromAddr, formState.delegateAsset, delegatedAmountMap, assetInfo.decimals])

  const bnAmount = useMemo(() => {
    const parsedAmount = formState.input.replace(/,/g, "")
    return new BigNumber(parsedAmount)
  }, [formState.input])

  useEffect(() => {
    if (bnAmount.gt(delegatedBalance)) {
      setInputError("The amount entered exceeds your delegated balance")
    } else {
      setInputError("")
    }
    // eslint-disable-next-line 
  }, [bnAmount, delegatedBalance, adjustedFee])

  useEffect(() => {
    if (!walletAddress) {
      setButtonState({ enabled: false, text: "Please Connect Wallet" })
      return
    } else if (formState.input === "0" || formState.input === "") {
      setButtonState({ enabled: false, text: "Enter Amount" })
      return
    } else if (!formState.delegateFromAddr || !formState.delegateToAddr) {
      setButtonState({ enabled: false, text: "Select Validators" })
    } else if (formState.delegateFromAddr === formState.delegateToAddr) {
      setButtonState({ enabled: false, text: "Select Different Validators" })
    } else {
      setButtonState({ enabled: true, text: "Redelegate" })
      return
    }
  }, [walletAddress, sdk, network, formState])

  const numbersClickHandler = (percentage: number) => {
    if (delegatedBalance !== BN_ZERO) {
      recomputeState({
        ...formState,
        input: delegatedBalance.times(percentage / 100).toString(),
        percentage: percentage,
      })
    }
  }

  const handleStakeSubmit = () => {
    if (!formState.delegateFromAddr || !formState.delegateToAddr || formState.input === "0" || formState.input === "") return
    // eslint-disable-next-line no-alert
    if (window.confirm(
      'Are you sure? There is a 30-day waiting time if you wish to redelegate stake from the new validator in the future.',
    )) {

      runRestake(async () => {
        if (!sdk?.wallet) return
        const params = {
          delegatorAddress: sdk.wallet.bech32Address,
          validatorSrcAddress: formState.delegateFromAddr,
          validatorDstAddress: formState.delegateToAddr,
          amount: bnOrZero(formState.input).dp(assetInfo.decimals, BigNumber.ROUND_DOWN).shiftedBy(assetInfo.decimals),
        }
        try {
          if (formState.delegateAsset === "swth") {
            await sdk.staking.redelegateTokens(params, { feeDenom: "swth" })
          } else {
            await sdk.alliance.redelegateTokens({
              ...params,
              denom: formState.delegateAsset,
            }, { feeDenom: "swth" })
          }
          setPopup(true)
        } catch (err) {
          const error = parseError(err)
          dispatch(updateStakeAsync({ error }))
          dispatch(addToastItem({
            id: uuidv4(),
            senderAddress: walletAddress!,
            receiverAddress: null,
            senderChain: ChainNames.CarbonCore,
            receiverChain: null,
            transactionHash: null,
            error: error
          }))
        } finally {
          dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_STAKING_INFO))
        }

        dispatch(reloadUserDelegations())
      })
    }
  }

  const inputChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    if (Number.isNaN(parsedTargetValue)) {
      recomputeState({
        ...formState,
        input: "",
        percentage: 0,
      })
    } else if (filteredTargetValue.split(".").length === 2) {
      const [int, decimals] = filteredTargetValue.split(".")
      let percentage = new BigNumber(parsedTargetValue).dividedBy(delegatedBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(int).toNumber() + `.${decimals}`,
        percentage: percentage,
      })
    } else {
      let percentage = new BigNumber(parsedTargetValue).dividedBy(delegatedBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(parsedTargetValue).toString(),
        percentage: percentage,
      })
    }
  }

  const inputSliderHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    recomputeState({
      ...formState,
      input: delegatedBalance.multipliedBy(parsedTargetValue / 100).toString(),
      percentage: parsedTargetValue,
    })
  }

  const changeRedelegationTo = (valID: number) => {
    const validator = validators[valID]
    recomputeState({
      ...formState,
      delegateTo: validator,
      delegateToAddr: validator?.operatorAddress,
    })
  }

  const changeRedelegationFrom = (valID: number) => {
    const delegatedVal = delegatedAddrOptions[valID]
    recomputeState({
      ...formState,
      delegateFrom: delegatedVal,
      delegateFromAddr: delegatedVal.operatorAddress,
    })
  }

  const changeAssetHandler = (assetIdx: number) => {
    const asset = assets[assetIdx]
    recomputeState({
      ...formState,
      delegateAsset: asset,
    })
  }


  return (
    <div className="redelegate-card-wrapper theme-color">
      <div className={`redelegate-card-container + ${popup ? "no-padding" : ""}`}>
        <div className={`redelegate-card-back-button-wrapper bolded-700" + ${popup ? "active" : ""}`}>
          <div className="redelegate-card-back-button" onClick={() => navigate("/stake")} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
            <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
            <span>Back</span>
          </div>
        </div>
        {!popup ?
          <div className='redelegate-card theme-color'>
            <div className="redelegate-card-header theme-color">
              <p>Redelegate </p>
              <div style={{ display: "flex", gap: "6px" }}>
                <WalletButton />
              </div>
            </div>
            <div className="validator-info-wrapper">
              <div className="validator-name-wrapper">
                <p>Redelegate From</p>
                <div className="validator-dropdown-wrapper" style={{ height: "48px" }}>
                  <DropDown selectOption={changeRedelegationFrom} defaultOption={defaultOption} options={delegatedOptions} />
                </div>
              </div>
              <div className="validator-name-wrapper">
                <p>Redelegate To</p>
                <div className="validator-dropdown-wrapper" style={{ height: "48px" }}>
                  <DropDown selectOption={changeRedelegationTo} defaultOption={""} options={valOptions} />
                </div>
              </div>
            </div>
            <div className="stake-input-wrapper">
              <p>Amount</p>
              <label className="stake-amount-input" style={inputError ? { border: "1px solid #DC6D5E" } : undefined}>
                <input type="number" placeholder="0" min="0" id="amount" value={formState.input} onChange={inputChangeHandler} style={inputError ? { color: "#DC6D5E" } : undefined} disabled={delegatedBalance.lte(BN_ZERO)} />
                <DropDown className="stake-asset-dropdown" selectOption={changeAssetHandler} defaultOption={formState.delegateAsset ? assets.findIndex(asset => asset === formState.delegateAsset) : ""} options={assetOptions} />
              </label>
              <InputSlider value={formState.percentage} disabled={delegatedBalance.lte(BN_ZERO)} numbersClickHandler={numbersClickHandler} onChange={(e: React.FormEvent<HTMLInputElement>) => inputSliderHandler(e)} onFocus={() => { }}
              />
            </div>
            <div className="stake-balance-box">
              <img
                src={(hoverRefreshBalance && delegatedBalance !== BN_ZERO) ? RefreshBranding : Refresh}
                alt="Refresh"
                onClick={() => {
                  dispatch(reloadUserDelegations())
                  setClickedRefreshBalance(true)
                }}
                onMouseOver={() => {
                  setHoverRefreshBalance(true)
                }}
                onMouseLeave={() => setHoverRefreshBalance(false)}
                onAnimationEnd={() => {
                  setClickedRefreshBalance(false)
                }}
                className={`${clickedRefreshBalance ? "stake-refresh-rotate" : ""} ${!(delegatedBalance === BN_ZERO) ? "stake-refresh-pointer" : ""}`} />
              <div className="stake-balance">
                <span className="input-error">{inputError}</span>
                <span style={{ whiteSpace: "nowrap" }}>Balance:{" "}{delegatedBalance.toFormat()}</span>
              </div>
            </div>
            <div className="stake-details">
              <div><span>Lock Period </span><span>30 Days</span></div>
              <div><span>Estimated Fee </span><span>{adjustedFee.amount.toString(10)} {adjustedFee.symbol}</span></div>
              <div><span id="usd">USD </span><span>${adjustedFee.amount.multipliedBy(adjustedFee.price).toString(10)}</span></div>
            </div>
            <Notification category="warning" header="Warning" boldMessage={<React.Fragment>Redelegating to another validator is <b>instant</b>. However, please note that:</React.Fragment>} list={notificationList} />

            {isLedgerDisabled && (
              <div style={{ marginTop: "16px" }}>
                <Notification category="warning" header="Staking Alliance assets with Ledger is currently not supported, please connect with Keplr or Leap wallet. Alternatively, you can still stake SWTH with ledger." />
              </div>
            )}
            <button id="bottom-button" className="button-theme button-theme-primary" disabled={!buttonState.enabled || !!inputError || isLedgerDisabled} onClick={handleStakeSubmit}>
              {loadingRestake ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : buttonState.text}
            </button>
          </div>
          : <RedelegatePopup
            valTo={formState.delegateTo!}
            valFrom={formState.delegateFrom!}
            setPopup={setPopup}
            resetFormState={resetFormState}
            delegateAmount={parseNumber(formState.input)!.toFormat()}
            delegateUSD={parseNumber(formState.input)!.multipliedBy(assetInfo.price).toFormat(2)}
          />}
      </div>
    </div>
  )
}

export default RedelegateCard
